import { Injectable } from '@angular/core';
import { Loader } from '@googlemaps/js-api-loader';

export type MapsLibraryType = 'core' | 'maps' | 'drawing';

@Injectable({ providedIn: 'root' })
export class MapsLoaderService {
    loader: Loader;
    isLoaded = false;

    constructor() {
        this.loader = new Loader({
            // TODO: move this to environment variable.
            apiKey: 'AIzaSyC3HL5_AY95PfhkGmUiBurfuKiMlb582fQ',
            version: 'weekly',
            libraries: [],
        });
    }

    async load(libraries: MapsLibraryType[]) {
        for (const library of libraries) {
            await this.loader.importLibrary(library);
        }
        this.isLoaded = true;
    }
}
